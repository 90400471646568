import React from "react"
import Layout from "../template/index"
import SEO from "../utils/seo"

const Contact = () => {
  return (
    <Layout>
      <SEO title="Contact Us" />
    </Layout>
  )
}

export default Contact
